interface SVGIconProps {
    width?: string;
    height?: string;
    fill?: string;
    cssClass?: string;
}

export function SVGPhone( { width = "32", height = "32", fill = "currentColor" }: SVGIconProps ){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm90.7 96.7c9.7-2.6 19.9 2.3 23.7 11.6l20 48c3.4 8.2 1 17.6-5.8 23.2L168 231.7c16.6 35.2 45.1 63.7 80.3 80.3l20.2-24.7c5.6-6.8 15-9.2 23.2-5.8l48 20c9.3 3.9 14.2 14 11.6 23.7l-12 44C336.9 378 329 384 320 384C196.3 384 96 283.7 96 160c0-9 6-16.9 14.7-19.3l44-12z"/></svg>
    )
}

export function SVGWhatsapp( { width = "32", height = "32", fill = "currentColor" }: SVGIconProps ){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 448 512"><path d="M92.1 254.6c0 24.9 7 49.2 20.2 70.1l3.1 5-13.3 48.6L152 365.2l4.8 2.9c20.2 12 43.4 18.4 67.1 18.4h.1c72.6 0 133.3-59.1 133.3-131.8c0-35.2-15.2-68.3-40.1-93.2c-25-25-58-38.7-93.2-38.7c-72.7 0-131.8 59.1-131.9 131.8zM274.8 330c-12.6 1.9-22.4 .9-47.5-9.9c-36.8-15.9-61.8-51.5-66.9-58.7c-.4-.6-.7-.9-.8-1.1c-2-2.6-16.2-21.5-16.2-41c0-18.4 9-27.9 13.2-32.3c.3-.3 .5-.5 .7-.8c3.6-4 7.9-5 10.6-5c2.6 0 5.3 0 7.6 .1c.3 0 .5 0 .8 0c2.3 0 5.2 0 8.1 6.8c1.2 2.9 3 7.3 4.9 11.8c3.3 8 6.7 16.3 7.3 17.6c1 2 1.7 4.3 .3 6.9c-3.4 6.8-6.9 10.4-9.3 13c-3.1 3.2-4.5 4.7-2.3 8.6c15.3 26.3 30.6 35.4 53.9 47.1c4 2 6.3 1.7 8.6-1c2.3-2.6 9.9-11.6 12.5-15.5c2.6-4 5.3-3.3 8.9-2s23.1 10.9 27.1 12.9c.8 .4 1.5 .7 2.1 1c2.8 1.4 4.7 2.3 5.5 3.6c.9 1.9 .9 9.9-2.4 19.1c-3.3 9.3-19.1 17.7-26.7 18.8zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM148.1 393.9L64 416l22.5-82.2c-13.9-24-21.2-51.3-21.2-79.3C65.4 167.1 136.5 96 223.9 96c42.4 0 82.2 16.5 112.2 46.5c29.9 30 47.9 69.8 47.9 112.2c0 87.4-72.7 158.5-160.1 158.5c-26.6 0-52.7-6.7-75.8-19.3z"/></svg>
    )
}

export function SVGEmail( { width = "32", height = "32", fill = "currentColor" }: SVGIconProps ){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill}  viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM218 271.7L64.2 172.4C66 156.4 79.5 144 96 144H352c16.5 0 30 12.4 31.8 28.4L230 271.7c-1.8 1.2-3.9 1.8-6 1.8s-4.2-.6-6-1.8zm29.4 26.9L384 210.4V336c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V210.4l136.6 88.2c7 4.5 15.1 6.9 23.4 6.9s16.4-2.4 23.4-6.9z"/></svg>
    )
}

export function SVGEdit( { width = "32", height = "32", fill = "currentColor" }: SVGIconProps ){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 448 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
    )
}

export function SVGCopy( { width = "32", height = "32", fill = "currentColor" }: SVGIconProps ){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 448 512"><path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"/></svg>
    )
}

export function SVGGithub( { width = "32", height = "32", fill = "currentColor" }: SVGIconProps ){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 496 512"><path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"/></svg>
    )
}

export function SVGInstagram({ width = "32", height = "32", fill = "currentColor" }: SVGIconProps){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
    )
}

export function SVGLinkedin({ width = "32", height = "32", fill = "currentColor" }: SVGIconProps){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
    )
}

export function SVGDate({ width = "32", height = "32", fill = "currentColor", cssClass = ""}: SVGIconProps){
    return(
        <svg
            className={cssClass}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 448 512"
        >
            <path d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z" />
        </svg>
    )
}

export function SVGTime({ width = "32", height = "32", fill = "currentColor", cssClass = ""}: SVGIconProps){
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill={fill}
            className={cssClass}
            viewBox="0 0 512 512"
        >
            <path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
        </svg>
    )
}

export function SVGLogo({ width = "32", height = "32", fill = "currentColor", cssClass = ""}: SVGIconProps){
    return(
        <svg 
            className={cssClass}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill={fill}
            viewBox="100 90 300 330"
        >
        <path d="
        M292.766907,295.811035 
            C288.325104,299.164520 287.759216,302.958466 289.271576,307.904724 
            C295.594788,328.584595 301.629883,349.352936 307.695374,370.110840 
            C308.388123,372.481567 309.828247,373.594879 312.063538,374.340271 
            C319.421173,376.793762 323.522003,381.874939 324.339783,389.697021 
            C325.234863,398.258484 321.518646,404.222290 314.150543,407.986572 
            C311.226257,409.480560 310.037415,411.314911 310.083527,414.590912 
            C310.238129,425.582092 310.023071,436.566803 309.451080,447.555847 
            C308.967072,456.854919 304.033508,462.226562 294.545288,462.291962 
            C277.218994,462.411377 259.891266,462.321503 242.564148,462.323975 
            C230.568436,462.325684 218.572601,462.363525 206.577103,462.316650 
            C204.428299,462.308258 202.222824,462.216064 200.142731,461.734161 
            C190.852966,459.582153 189.697968,458.030090 189.728775,448.438873 
            C189.765167,437.109833 189.732498,425.779846 189.887161,414.452423 
            C189.930222,411.299072 189.149719,409.302277 186.018661,407.801392 
            C179.091187,404.480591 175.387878,398.652191 175.818390,390.926727 
            C176.243561,383.297394 180.348846,377.755280 187.458237,374.907166 
            C190.289627,373.772858 191.708511,371.998108 192.513550,369.290161 
            C198.963181,347.595642 205.380554,325.891144 211.981888,304.242737 
            C212.835678,301.442871 211.239182,300.211578 209.789612,298.714142 
            C196.120300,284.593262 182.301361,270.615112 168.763412,256.369781 
            C156.162354,243.110336 142.763153,230.479950 134.070923,213.986649 
            C123.867332,194.625641 119.789429,174.112015 121.435303,152.273880 
            C124.027748,117.876022 139.460251,89.933571 164.437668,67.057655 
            C185.312332,47.939289 210.099442,37.240082 238.468109,34.552216 
            C270.937286,31.475832 300.303833,39.425808 326.286407,58.827473 
            C355.282593,80.479492 373.793549,108.860855 378.333344,145.326096 
            C383.049408,183.207306 371.495331,215.450928 344.490845,242.460968 
            C327.064911,259.890472 310.161285,277.842255 292.766907,295.811035 
        M242.737350,85.500023 
            C242.732407,80.166740 242.742767,74.833382 242.718628,69.500183 
            C242.688950,62.946236 241.835556,62.344982 235.389145,63.834858 
            C188.258026,74.727669 162.922653,126.192314 176.593552,170.587509 
            C180.520401,183.339691 188.377899,194.048645 194.835495,205.460922 
            C207.058121,227.061523 219.648758,248.454147 232.123749,269.911530 
            C235.922043,276.444733 238.841599,283.416840 242.301682,290.216949 
            C242.771744,280.622406 242.770966,271.164673 242.771927,261.706970 
            C242.773483,246.374039 242.809204,231.041016 242.763641,215.708221 
            C242.742661,208.648743 240.871765,207.081604 233.645859,207.320694 
            C232.429733,207.360916 231.063843,206.643723 229.969330,205.955109 
            C224.458954,202.488251 219.308640,198.574951 214.878403,193.741501 
            C187.216843,163.562271 203.187668,117.631271 238.618408,110.573212 
            C241.904068,109.918678 242.887390,108.186890 242.788345,104.997879 
            C242.596985,98.837296 242.733688,92.666527 242.737350,85.500023 
        M312.635437,193.154770 
            C317.012115,185.669922 321.507660,178.135941 323.597626,169.697128 
            C331.936096,136.028214 325.057465,106.398338 299.435608,82.012733 
            C289.518829,72.574463 277.584625,66.945518 264.450165,63.681046 
            C261.288513,62.895241 257.984528,62.248001 258.044739,67.517174 
            C258.189423,80.175186 258.188721,92.835716 258.123627,105.494598 
            C258.109100,108.319771 259.235809,109.483467 261.939941,110.298508 
            C293.485687,119.806725 307.658295,150.002167 294.146057,179.845963 
            C287.880951,193.683411 278.507629,205.156937 263.233215,209.892776 
            C259.056641,211.187729 257.713989,213.129593 257.853485,217.277893 
            C258.272980,229.751633 257.859283,242.224197 257.499268,254.700821 
            C257.170563,266.092926 257.429565,277.502014 257.429565,288.903595 
            C257.869171,288.947266 258.308777,288.990936 258.748383,289.034607 
            C259.114136,288.345367 259.537079,287.680023 259.837158,286.963318 
            C265.585144,273.233856 273.900238,260.912140 281.102356,247.977264 
            C287.333038,236.787125 294.654114,226.177719 299.757172,214.399261 
            C301.935181,209.372147 303.633575,204.273697 307.650940,200.261688 
            C309.500549,198.414566 310.739227,195.955704 312.635437,193.154770 
        M189.766449,70.329872 
            C191.152069,69.043457 192.537674,67.757042 193.923294,66.470627 
            C184.570160,71.174156 176.466827,77.408852 169.185257,84.802536 
            C159.340820,94.798538 152.737183,106.908562 146.433167,119.206085 
            C145.680695,120.673973 142.807953,122.783051 146.485962,124.746445 
            C147.126419,125.088348 146.344147,127.860718 145.136276,127.998772 
            C141.189484,128.449860 141.179077,131.794388 140.515198,134.197922 
            C139.236710,138.826675 138.311844,143.608414 137.808105,148.386566 
            C135.141571,173.680344 139.774780,197.369858 155.678925,217.673370 
            C174.296570,241.441025 196.842636,261.637726 217.463684,283.591125 
            C217.804459,283.953918 218.193954,284.274506 218.581772,284.588837 
            C219.515778,285.345886 220.538605,286.087646 221.703598,285.178680 
            C222.920624,284.229095 222.239731,283.069519 221.757004,282.017700 
            C221.340622,281.110443 220.890854,280.213348 220.386719,279.352264 
            C204.406448,252.055878 188.396225,224.776993 172.443024,197.464798 
            C167.485855,188.977997 162.363907,180.474319 160.092346,170.817093 
            C150.970444,132.036728 161.225281,98.804344 189.766449,70.329872 
        M356.405457,124.268288 
            C350.731903,111.672798 344.671783,99.357124 335.231354,88.967087 
            C327.641449,80.613724 314.262329,69.803009 307.483856,67.309921 
            C307.686279,67.564613 307.858917,67.851707 308.095367,68.069435 
            C340.260223,97.686966 349.598236,133.789566 338.812134,175.690872 
            C337.744141,179.839783 334.616730,183.968384 334.669464,187.393402 
            C334.736633,191.759277 334.066467,193.273758 329.304901,193.816483 
            C332.184937,198.030380 332.130890,200.962540 326.429749,201.096344 
            C325.788818,201.111389 325.081360,202.479248 324.572632,203.321213 
            C315.533630,218.281815 306.885468,233.495041 297.371216,248.146072 
            C291.243317,257.582428 288.123108,268.611389 280.850739,277.425812 
            C278.870087,279.826477 276.926025,283.172546 277.683472,287.032501 
            C279.985535,287.268311 280.737000,285.722656 281.757874,284.421021 
            C284.403595,281.047668 286.559235,276.850067 289.978699,274.617676 
            C296.536407,270.336426 300.187073,263.468658 306.350525,258.597229 
            C317.732941,249.600815 327.099548,237.987976 336.974152,227.162476 
            C364.338928,197.162552 369.454559,162.760147 356.405457,124.268288 
        M268.634521,133.791916 
            C261.435730,129.018372 253.425751,128.322891 245.122940,129.130722 
            C234.116776,130.201599 225.950470,136.141373 220.777145,145.368439 
            C213.741730,157.916687 217.097366,174.164322 227.668137,183.724991 
            C238.079376,193.141373 255.483780,194.725571 267.235260,187.763977 
            C285.373993,177.018570 290.088287,147.068359 268.634521,133.791916 
        M286.690704,350.666260 
            C283.073761,338.484985 279.456818,326.303711 275.772766,313.896423 
            C274.745789,314.482666 274.244965,314.642212 273.918823,314.973633 
            C268.661133,320.316406 263.408295,325.664154 258.189056,331.044495 
            C249.024734,340.491669 251.638290,341.343170 241.631134,331.271332 
            C236.165268,325.770142 230.452621,320.514130 224.038467,314.368835 
            C218.145218,334.096436 212.393341,353.350830 206.605148,372.726746 
            C214.570541,375.746155 218.134323,368.296600 224.309280,368.164673 
            C218.917908,357.937775 219.188019,351.260925 224.728683,346.714874 
            C229.023331,343.191223 234.006760,343.232758 238.397964,346.788879 
            C241.007828,348.902466 242.176208,351.830658 242.947189,354.928497 
            C244.211365,360.008148 240.678787,363.593567 238.377060,367.936462 
            C242.154160,368.983948 245.724915,369.803528 248.167587,372.921753 
            C249.189819,374.226685 250.964798,373.909790 252.300446,373.011749 
            C254.325912,371.650055 256.251190,370.139282 258.166138,368.730743 
            C253.521988,359.369202 253.934601,351.971100 259.197723,347.407288 
            C264.208771,343.062103 270.516296,342.728516 275.760345,346.464325 
            C278.503723,348.418701 279.753571,351.237274 280.751892,354.307312 
            C282.494507,359.666077 280.563568,364.345306 278.017242,368.739349 
            C280.038635,370.242065 281.681458,371.673737 283.520142,372.779724 
            C286.112366,374.338959 289.253326,374.417480 291.647003,373.106995 
            C294.029663,371.802582 292.249725,369.146912 291.697357,367.200562 
            C290.198151,361.918121 288.480316,356.697693 286.690704,350.666260 
        M197.844955,387.166626 
            C194.495209,387.145660 191.097061,387.396637 191.118668,391.838074 
            C191.139786,396.176361 194.490173,396.587402 197.844757,396.650452 
            C198.343704,396.659851 198.843033,396.647064 199.342194,396.647308 
            C233.118744,396.663361 266.895294,396.690125 300.671844,396.681152 
            C304.418884,396.680145 309.425903,397.485016 309.381592,391.975586 
            C309.338409,386.602997 304.376617,387.220398 300.603088,387.215973 
            C266.660065,387.176208 232.716995,387.176392 197.844955,387.166626 
        M284.500610,412.873077 
            C279.869080,410.690521 276.711487,417.116486 272.213654,414.497894 
            C270.418884,421.808319 271.017181,422.636108 277.720612,422.663025 
            C282.216461,422.681061 286.713562,422.712311 291.207703,422.619812 
            C292.645935,422.590240 294.444763,422.642609 294.724182,420.626129 
            C294.948059,419.010925 295.488739,416.953003 294.066681,415.886932 
            C291.527130,413.983063 289.195496,410.831879 284.500610,412.873077 
        M289.175385,448.009460 
            C291.802826,447.776215 294.825836,448.051544 294.755219,444.063080 
            C294.686249,440.169739 291.186920,440.616211 289.221802,440.673828 
            C283.381470,440.844940 277.420410,439.675232 271.777588,441.434296 
            C270.335388,446.318329 272.115631,448.124512 276.815430,447.886566 
            C280.612976,447.694305 284.433929,447.964142 289.175385,448.009460 
        M238.234695,417.788025 
            C237.712830,421.490997 239.303986,422.954254 243.022919,422.708832 
            C246.340927,422.489868 249.689148,422.561951 253.016342,422.696259 
            C256.311798,422.829315 256.856476,420.956512 256.821686,418.254303 
            C256.789215,415.732056 256.156097,413.987915 253.199783,414.088898 
            C249.207947,414.225311 245.215164,414.346710 241.221802,414.421082 
            C239.459579,414.453918 238.334167,415.088104 238.234695,417.788025 
        M209.085571,414.945923 
            C205.857712,414.364563 205.099594,416.061371 205.114075,418.907349 
            C205.128265,421.691925 206.306808,422.772125 209.022018,422.689728 
            C212.495605,422.584290 215.978394,422.569977 219.450638,422.696594 
            C222.417038,422.804810 223.571274,421.561615 223.630890,418.597595 
            C223.695663,415.377808 222.378311,414.107422 219.231003,414.128204 
            C216.079300,414.148987 212.952408,414.191833 209.085571,414.945923 
        M253.105240,447.957825 
            C256.271423,448.057312 256.988068,446.181152 256.892090,443.457825 
            C256.782776,440.357635 254.756134,440.108612 252.422150,440.242401 
            C247.853165,440.504242 243.285919,440.796387 238.782867,441.072021 
            C237.770279,447.416168 237.983765,447.731720 243.741348,447.922913 
            C246.557449,448.016418 249.378372,447.964325 253.105240,447.957825 
        M209.901199,447.877686 
            C213.048691,447.900940 216.196167,447.936066 219.343704,447.943878 
            C222.144440,447.950836 223.046631,446.342377 223.004105,443.768799 
            C222.962601,441.257141 221.744614,440.092194 219.306076,440.217010 
            C214.739456,440.450684 210.175949,440.744934 205.707458,441.008301 
            C204.907272,446.512238 204.907272,446.512238 209.901199,447.877686 
        z"/>
        </svg>
    )
}